* {
    box-sizing: border-box;

    font-family: "Syncopate", sans-serif;
    font-weight: 700;
    font-style: normal;
}

html,
body,
#root,
.page_box {
    width: 100%;
    height: 100%;
}

.regular {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

.bold {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 6px;
    margin-right: 0px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar-thumb {
    /* 滑块 */
    width: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块 */
    background: #909090;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.header-box {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0.42rem 0 0 0.48rem;
    justify-content: space-between;
    @media (max-width: 900px) {
        padding: 0.45rem 0.3rem;
    }
    .text {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 0.24rem;
        color: #fff;
        line-height: 0.35rem;
    }
    .logo_box {
        display: flex;
        align-items: center;
        @media (max-width: 900px) {
            display: none;
        }
        .logo {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: .08rem;

            img {
                width: 100%;
                height: 100%;
                border-radius: .08rem;
            }
        }
        .text {
            margin-left: 0.14rem;
        }
    }
    .link_box {
        margin-left: 0.58rem;
        @media (max-width: 900px) {
            margin-left: 0;
            display: flex;
            width: 100%;
        }
        .text {
            color: #c3c3c3;
            padding: 0 0.47rem;
            @media (max-width: 900px) {
                display: block;
                width: 35%;
                font-size: 0.2rem;
                line-height: 0.3rem;
                padding: 0;
                text-align: center;
            }
        }

        .ml {
            border-left: 0.03rem solid rgba(255, 255, 255, 0.2);
            border-right: 0.03rem solid rgba(255, 255, 255, 0.2);
        }
    }
}

.page {
    width: 100%;
    height: 100%;
    background: url("../images/bg.png") no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
        background: url("../images/bg1.png") no-repeat;
        background-size: cover;
        background-position: center center;
        justify-content: end;
    }
    .app_store {
        margin-top: 7%;
        margin-right: 9%;
        width: 3.68rem;
        @media (max-width: 900px) {
            margin-top: 0;
            margin-bottom: 38%;
            margin-right: 8%;
        }
        img {
            width: 100%;
        }
    }
}

.pact-page {
    height: 100%;
    width: 100%;
    background: url("../images/box_bg.png") no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
        background: url("../images/box_bg1.png") no-repeat;
        background-size: 100% 100%;
        background-position: center center;
    }
    .box {
        width: 70%;
        height: 65%;
        @media (max-width: 900px) {
            width: 80%;
            height: 85%;
        }
        .title {
            font-family: "Carattere", cursive;
            font-weight: 600;
            font-size: 0.8rem;
            color: #ffffff;
            line-height: 1.12rem;
            margin-bottom: 0.43rem;
            background: linear-gradient(
                23.65960595864194deg,
                #eece57 0%,
                #feeaa0 30%,
                #efdd9b 65%,
                #d7bd64 85%,
                #dfc974 100%
            );
            -webkit-background-clip: text !important;
            -webkit-text-fill-color: transparent !important;
            @media (max-width: 900px) {
                font-size: 0.8rem;
                line-height: 1.10rem;
               
                margin-bottom: 0.15rem;
            }
        }
        .pact-cont {
            height: 80%;
            overflow: auto;
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            @media (max-width: 900px) {
                height: 90%;
            }
            .text_box {
                display: flex;
                margin-top: 0.26rem;
                @media (max-width: 900px) {
                    flex-direction: column;
                }
                h2 {
                    width: 30%;
                    font-family: "Heebo", sans-serif;
                    font-weight: 500;
                    font-size: 0.22rem;
                    color: #ffffff;
                    line-height: 0.32rem;
                    margin-bottom: 0.3rem;
                    @media (max-width: 900px) {
                        font-size: 0.28rem;
                        line-height: 0.33rem;
                        width: 100%;
                    }
                }
                .text {
                    flex: 1;
                    p,
                    li {
                        font-family: "Poppins", sans-serif;
                        font-weight: 400;
                        font-size: 0.22rem;
                        color: #ffffff;
                        line-height: 0.3rem;
                        padding-bottom: 0.2rem;
                        @media (max-width: 900px) {
                            font-size: 0.24rem;
                            line-height: 0.33rem;
                        }
                    }
                    strong {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
